import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, throwError } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { OrganizationModel } from '../_models/organization.model';
import { PasswordPayload } from '../_models/password-payload.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  currentOrganization$: Observable<OrganizationModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  currentOrganizationSubject: BehaviorSubject<OrganizationModel>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  // Set selected organization as shared Observable
  set currentOrganizationValue(organization: OrganizationModel) {
    this.currentOrganizationSubject.next(organization);
  }

  get currentOrganizationValue(): OrganizationModel {
    return this.currentOrganizationSubject.value;
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.currentOrganizationSubject = new BehaviorSubject<OrganizationModel>(undefined);
    this.currentOrganization$ = this.currentOrganizationSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        console.log(auth);

        this.setAuthFromLocalStorage(auth);
        const result = auth['items'];
        this.currentUserSubject = new BehaviorSubject<UserModel>(result);

        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // public methods
  login2(email: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    this.currentUserSubject.next(null);
    this.isLoadingSubject.next(false);
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.clear();
    this.router.navigate(['/auth/login'], { queryParams: {}, }).
      then(() => {
        document.location.reload();
      });
  }

  logout2() {
    // localStorage.removeItem(this.authLocalStorageToken);
    // localStorage.clear();
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: UserModel) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(user);

          let organizations: OrganizationModel[] = user.organizations;

          console.log(user);

          if (organizations && organizations.length > 0) {
            let org: OrganizationModel = organizations[0];
            this.currentOrganizationSubject = new BehaviorSubject<OrganizationModel>(org);
            //this.setOrganization(organizations[0]);
          }

        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // create new password then login
  createPassword(payload: PasswordPayload): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createPassword(payload).pipe(
      map((response: any) => {
        this.isLoadingSubject.next(false);
        let data = { email: response.items.email };
        return data;
      }),
      switchMap((data) => this.login(data.email, payload.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  setupAccount(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.accountSetup(user).pipe(
      map((user: any) => {
        this.isLoadingSubject.next(false);
        console.log(user);
        return user;
      }),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/expiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  refreshToken(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.refreshToken().pipe(
      map((auth: AuthModel) => {
        console.log(auth);

        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap((user) => this.getUserByToken()),
      catchError((error) => {
        console.error('err', error);
        //return of({ x: 0, y: 0 });
        //return throwError(err);
        // this.logout();
        return throwError(() => error);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // Get invitation by 
  getInvitationByToken(token: string): Observable<any> {
    return this.authHttpService.getInvitation(token).pipe(
      map((invitation: any) => {
        console.log(invitation);
        return invitation;
      }),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
