import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {

      const userRole: string[] = currentUser.permissions;
      /*
      if (route.data.userRoles && route.data.role.indexOf(userRole) === -1) {
        //this.router.navigate(['/home']);
        return false;
      }
      */

      if (!(route.data.userRoles == null || route.data.userRoles == undefined)) {
        const found = route.data.userRoles.some(role => userRole.indexOf(role) >= 0)

        if (route.data.userRoles && !found) {
          this.router.navigate(['/']);

          return false;
        }
      }
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout();
    return false;
  }
}
