import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';
import { PasswordPayload } from '../../_models/password-payload.model';

const API_USERS_URL = `${environment.apiUrl}/auth`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient, private httpBackend: HttpBackend) { }

  // public methods
  login(email: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(`${API_USERS_URL}/login`, { email, password });
    // return this.http.post<AuthModel>(`${API_USERS_URL}/api/token/`, { username: email, password });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Get invitation
  getInvitation(token: string): Observable<any> {
    return this.http.get<any>(`${API_USERS_URL}/invitation/${token}`);
  }

  // ACCOUNT SETUP =>  POST: add a new user to the server
  accountSetup(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(`${API_USERS_URL}/account/setup`, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  // CREATE =>  POST: create new passowrd
  createPassword(payload: PasswordPayload): Observable<PasswordPayload> {
    return this.http.post<PasswordPayload>(`${API_USERS_URL}/new-password`, payload);
  }

  getUserByToken(token): Observable<UserModel> {
    return this.http.get<UserModel>(`${API_USERS_URL}/me`);
  }

  refreshToken(): Observable<any> {
    //const httpClient = new HttpClient(this.httpBackend);

    let key = `${environment.appVersion}-${environment.USERDATA_KEY}`;
    let authData = JSON.parse(localStorage.getItem(key));

    //const headers = new HttpHeaders().set("Authorization", "Bearer " + authData.authToken);

    const headers = new HttpHeaders().set("Authorization", "Bearer " + authData.refreshToken);

    return this.http.post<AuthModel>(`${API_USERS_URL}/refresh`, null, { headers });
  }

}
